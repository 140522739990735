export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.parqueos.text',
        icon: 'ri-car-line',
        link: '/parkings'
    },
    {
        id: 4,
        label: 'menuitems.fees.text',
        icon: 'ri-exchange-dollar-line',
        link: '/fees'
    },
    {
        id: 5,
        label: 'menuitems.operadores.text',
        icon: 'ri-user-follow-line',
        link: '/employees'
    },
    {
        id: 6,
        label: 'menuitems.partners.text',
        icon: 'ri-service-line',
        link: '/partners'
    },
    {
        id: 7,
        label: 'menuitems.accesslist.text',
        icon: 'ri-home-3-line',
        link: '/access-list'
    },
    {
        id: 8,
        label: "menuitems.reports.text",
        isTitle: true
    },
    {
        id: 9,
        label: 'menuitems.tickets.text',
        icon: 'ri-ticket-line',
        link: '/tickets'
    },
    {
        id: 10,
        label: 'menuitems.invoices.text',
        icon: 'ri-file-paper-line',
        link: '/invoices'
    }
]